export const API_BASE_URL = process.env.VUE_APP_GENERATE_SAS_TOKEN;

// Statuses with Matching Index and Value
export const PIPELINE_STATUS = {
  UPLOADED: "Uploaded",
  IN_PROGRESS: "InProgress",
  QUEUED: "Queued",
  FAILED: "Failed",
  SUCCEEDED: "Succeeded",
};

// Messages matching the STATUS keys
export const MESSAGES = {
  UPLOADED: "File uploaded successfully.",
  FAILED: "File parsing error.",
  SUCCEEDED: "Authentication failed.",
};
