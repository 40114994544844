/**
 * Fetch API with authorization header
 * @param {string} url - API endpoint
 * @param {string} method - HTTP method (default: 'GET')
 * @param {string} token - Auth token
 * @param {Object} body - Request body (optional)
 */
export async function apiRequest(url, method = "GET", token, body = null) {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  const options = { method, headers };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);

  if (!response.ok) {
    throw new Error(`API request failed: ${response.statusText}`);
  }

  return response.json();
}