<template>
  <div class="mainUploadfile">
    <v-row no-gutters>
      <v-col cols="12" class="d-flex align-center justify-center mb-4 mt-16 pt-16">
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <label for="file-upload" class="custom-file-upload w-50 bg-red rounded text-capitalize">
              Upload file
            </label>
            <input id="file-upload" type="file" @change="handleFileChange" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="uploadStatus">
      <v-col cols="12" class="d-flex justify-center text-black mb-9">
        <p>{{ uploadStatus }}</p>
      </v-col>
    </v-row>
    <v-row no-gutters class="h-100 d-flex align-center justify-center">
      <v-col cols="12" sm="10" md="8" lg="6" class="h-100">
        <v-card class="mx-auto h-100">
          <v-layout>
            <v-app-bar color="#cbd6e2" density="compact" class="px-5">
              <div class="d-flex align-center justify-center">
                <v-img src="../assets/cloudIcon.png" height="30px" width="30px" class="mx-4"></v-img>
                <p class="font-weight-bold text-h5">Supply Chain Solutions®</p>
              </div>
              <div class="d-flex align-center justify-center">
                <p class="font-weight-regular mx-5">Upload</p>
                <v-menu>
                  <template v-slot:activator="{ props }">
                    <v-btn icon="mdi-dots-vertical" v-bind="props">
                      <v-img src="../assets/menu.png" height="20px" width="20px" class="mx-4"></v-img>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item>
                      <v-list-item-title @click="handleLogout" class="cursor-pointer">Logout</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-app-bar>

            <v-main class="w-100 px-3 py-3">
              <v-row class="mx-1">
                <v-col cols="8" sm="8" md="4" lg="4">
                  <h2>Uploaded Files</h2>
                </v-col>
                <v-col cols="2" class="d-flex justify-center align-center">
                  <p>Upload</p>
                </v-col>
                <v-col cols="2" class="d-flex justify-center align-center">
                  <p>Ingest</p>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" class="d-flex justify-center"></v-col>
              </v-row>
              <!-- Uploaded Files List -->
              <div v-if="files.length > 0">
                <div v-for="file in files" :key="file.name" class="mb-5">
                  <v-card class="mx-auto mb-5">
                    <v-row no-gutters class="mx-4 my-4">
                      <v-col cols="8" sm="6" md="4" lg="4" class="d-flex align-center mb-3">
                        <p>{{ file.rowKey }}</p>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-center align-center mb-3">
                        <div class="text-center">
                          <v-img src="../assets/icon-circle-check.png" height="40px" width="40px"></v-img>
                        </div>
                      </v-col>
                      <v-col cols="2" class="d-flex justify-center align-center mb-3">
                        <div class="text-center">
                          <v-menu v-if="file.status == statusTypes.UPLOADED">
                            <template v-slot:activator="{ props }">
                              <div v-bind="props">
                                <v-img src="../assets/Icon-play.png" height="40px" width="40px"
                                  class="cursor-pointer"></v-img>
                              </div>
                            </template>
                            <v-list>
                              <v-list-item v-for="(item, index) in pipelinesList" :key="index" :value="index"
                                :class="{ 'bg-gray': index === 0 }" @click="executePipeline(file.rowKey, item)">
                                <v-list-item-title>{{ item.pipeline_name }}</v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>

                          <v-img v-else-if="file.status == statusTypes.IN_PROGRESS || file.status == statusTypes.QUEUED"
                            src="../assets/icon-spinner.png" height="40px" width="40px"
                            v-tooltip="{ text: file.message, location: 'right', scrollStrategy: 'close', openOnClick: file.message ? true : false, openOnHover: false }"
                            class="cursor-pointer"></v-img>

                          <v-img v-else-if="file.status == statusTypes.SUCCEEDED" src="../assets/icon-circle-check.png"
                            height="40px" width="40px"
                            v-tooltip="{ text: file.message, location: 'right', scrollStrategy: 'close', openOnClick: file.message ? true : false, openOnHover: false }"
                            class="cursor-pointer"></v-img>

                          <v-img v-else src="../assets/Icon-error.png" height="40px" width="40px"
                            v-tooltip="{ text: 'Other status', location: 'right', scrollStrategy: 'close', openOnClick: true, openOnHover: false }"
                            class="cursor-pointer"></v-img>
                        </div>
                      </v-col>
                      <v-col cols="12" sm="2" md="4" lg="4" class="d-flex justify-end">
                        <v-btn variant="outlined" height="40" class="rounded text-capitalize"
                          @click="confirmDelete(file.rowKey)">Delete</v-btn>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </div>

              <!-- Show this message if there are no files -->
              <v-row v-else>
                <v-col cols="12" class="d-flex justify-center text-black mb-9">
                  <p>No files found</p>
                </v-col>
              </v-row>
              <v-btn @click="listFiles">Refresh File List</v-btn>
            </v-main>
          </v-layout>
        </v-card>
      </v-col>
    </v-row>

    <!-- Confirmation Dialog -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Confirm Delete</v-card-title>
        <v-card-text>Are you sure you want to delete this file?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="dialog = false">Cancel</v-btn>
          <v-btn color="green darken-1" text @click="deleteFile">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from "vue";
import { BlobServiceClient } from "@azure/storage-blob";
import { useAuth0 } from "@auth0/auth0-vue";
import { apiRequest } from "@/common/utils";
import { API_BASE_URL, PIPELINE_STATUS } from "@/common/constants";

export default {
  name: "FileUploadComponent",
  setup() {
    const { getAccessTokenSilently, logout } = useAuth0()

    const selectedFile = ref(null);
    const uploadStatus = ref("");
    const fileListStatus = ref("");
    const pipelinesList = ref([]);
    const statusTypes = ref(PIPELINE_STATUS);
    const files = ref([]);
    const accountName = process.env.VUE_APP_STORAGE_ACCOUNT_NAME;
    const dialog = ref(false);
    const fileToDelete = ref("");
    let sasToken = "";
    let userContainer = "";
    let authToken = "";

    const handleFileChange = (event) => {
      selectedFile.value = event.target.files[0];
      handleUpload();
    };

    const handleLogout = async () => {
      await logout({ logoutParams: { returnTo: window.location.origin } });
    };

    const getSasToken = async () => {
      uploadStatus.value = "Loading...";
      try {
        authToken = await getAccessTokenSilently();
        const response = await apiRequest(`${API_BASE_URL}/api/GenerateSasToken`, "GET", authToken);
        sasToken = response.sasToken;
        userContainer = response.containerName;
        pipelinesList.value = response.pipelinesListData;
        // Add a custom item at the top
        pipelinesList.value.unshift({
          pipeline_name: "Choose Pipeline",
        });
        listFiles();
      } catch (error) {
        handleLogout(); // Call logout if an error occurs
        throw new Error("Failed to fetch SCS token: " + error.message);
      }
    };

    const uploadFileToBlob = async (file) => {
      const blobServiceClient = new BlobServiceClient(
        `https://${accountName}.blob.core.windows.net?${sasToken}`
      );
      const containerClient = blobServiceClient.getContainerClient(userContainer);
      const blockBlobClient = containerClient.getBlockBlobClient(file.name);

      try {
        await blockBlobClient.uploadData(file);
      } catch (error) {
        throw new Error("Failed to upload file: " + error.message);
      }
    };

    const handleUpload = async () => {
      if (selectedFile.value) {
        uploadStatus.value = "Uploading..." + selectedFile.value.name;
        try {
          await uploadFileToBlob(selectedFile.value);
          uploadStatus.value = "File uploaded successfully!";

          // Get the auth token
          const authToken = await getAccessTokenSilently();

          // Call the API endpoint for fetching file list
          await apiRequest(
            `${API_BASE_URL}/api/ManageFileLogs`, // Adjust the endpoint URL as per your API design
            "POST",
            authToken,
            {
              "method_type": "add",
              "newRecord": {
                "fileName": selectedFile.value?.name,
                "status": PIPELINE_STATUS.UPLOADED
              }
            }
          );
          selectedFile.value = null;
          await listFiles(); // Refresh the file list after upload
          // Clear the status message after 3 seconds
          setTimeout(() => {
            uploadStatus.value = "";
          }, 3000);

        } catch (error) {
          console.error("File upload error:", error);
          uploadStatus.value = "File upload failed. Please try again.";
        }
      } else {
        uploadStatus.value = "Please select a file first.";
      }
    };

    const listFiles = async () => {
      try {
        uploadStatus.value = "Loading file list...";

        // Get the auth token
        const authToken = await getAccessTokenSilently();

        // Call the API endpoint for fetching file list
        const response = await apiRequest(
          `${API_BASE_URL}/api/ManageFileLogs`, // Adjust the endpoint URL as per your API design
          "POST",
          authToken,
          { method_type: "list" }
        );

        files.value = response?.uploadedFilesLog ?? [];

        uploadStatus.value = "";
      } catch (error) {
        console.error("Failed to list files:", error.message);
        uploadStatus.value = "";
      }
    };

    const confirmDelete = (fileName) => {
      fileToDelete.value = fileName;
      dialog.value = true; // Open the dialog
    };

    const deleteFile = async () => {
      try {
        const blobServiceClient = new BlobServiceClient(
          `https://${accountName}.blob.core.windows.net?${sasToken}`
        );
        const containerClient = blobServiceClient.getContainerClient(userContainer);
        const blobClient = containerClient.getBlobClient(fileToDelete.value);
        await blobClient.delete();

        dialog.value = false; // Close the dialog
        uploadStatus.value = "Loading file list...";

        // Get the auth token
        const authToken = await getAccessTokenSilently();

        // Call the API endpoint for fetching file list
        await apiRequest(
          `${API_BASE_URL}/api/ManageFileLogs`, // Adjust the endpoint URL as per your API design
          "POST",
          authToken,
          {
            "method_type": "delete",
            "recordId": fileToDelete.value
          }
        ).then(async response => {
          console.log("response", response);
          uploadStatus.value = "";
          files.value = [];
          await listFiles(); // Refresh the file list after deletion
        });
      } catch (error) {
        console.error("Failed to delete file:", error.message);
      }
    };

    const executePipeline = async (fileName, item) => {
      try {
        console.log("itemitem", item);
        uploadStatus.value = "Pipeline process start...";

        // Get the auth token
        const authToken = await getAccessTokenSilently();

        // Call the API endpoint for fetching file list
        const response = await apiRequest(
          `${API_BASE_URL}/api/ManagePipelines`, // Adjust the endpoint URL as per your API design
          "POST",
          authToken,
          {
            "method_type": "run_pipeline",
            "pipeline_name": item.pipeline_name,
            "file_name": fileName
          }
        );

        console.log("responseresponse", response);

        uploadStatus.value = "";
        files.value = [];
        await listFiles(); // Refresh the file list after deletion
      } catch (error) {
        console.error("Failed to list files:", error.message);
        uploadStatus.value = "";
      }
    };

    getSasToken();
    return {
      selectedFile,
      uploadStatus,
      fileListStatus,
      handleFileChange,
      handleUpload,
      listFiles,
      statusTypes,
      files,
      confirmDelete,
      deleteFile,
      dialog,
      pipelinesList,
      executePipeline,
      handleLogout
    };
  },
  mounted() {
    document.title = 'Manage files';
    // Refresh file list every 30 seconds (30000 ms)
    this.refreshInterval = setInterval(() => {
      this.listFiles();
    }, 30000);
  },
  beforeUnmount() {
    // Clear interval when component is destroyed
    clearInterval(this.refreshInterval);
  },

  data: () => ({
    tooltip: {
      text: 'ERROR: Azure data factory failed with error XYZ123 at 2023-10-01 00:00:00',
      scrollStrategy: 'close',
      persistent: false,
      openOnClick: true,
      openOnHover: false,
    },
  }),
};
</script>

<style lang="scss">
input[type="file"] {
  display: none;
}

.custom-file-upload {
  // display: inline-block;
  text-align: center;
  padding: 8px 12px;
  cursor: pointer;
}

.mainUploadfile {
  background-color: #fff;
  min-height: 100vh;
  width: 100vw;
  padding: 0 15px;
  overflow: hidden;
}

.v-toolbar__content {
  height: 64px !important;
  justify-content: space-between;
}

.mt-7 {
  margin-top: 7rem;
}

.bg-gray {
  background-color: rgb(203, 214, 226) !important;

  .v-list-item-title {
    font-size: 16px !important;
    color: #030303 !important;
    font-weight: 500 !important;
  }

}

.v-list {
  padding: 0px !important;
  text-align: center;
}

.v-tooltip>.v-overlay__content {
  width: 270px !important;
  background: white !important;
  color: #030303 !important;
  border: 1px solid #f7f2fa !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  line-height: 16px !important;
}

@media (max-width: 600px) {
  .mt-7 {
    margin-top: 3rem;
    /* Smaller margin on mobile */
  }

  .v-btn {
    font-size: 14px;
    /* Smaller button text size on mobile */
  }

  h2 {
    font-size: 22px;
    /* Smaller heading on mobile */
  }

  .v-btn {
    width: 100% !important;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .mt-7 {
    margin-top: 5rem;
    /* Adjust for tablet screens */
  }

  .v-btn {
    font-size: 16px;
  }

  h2 {
    font-size: 24px;
  }
}

@media (min-width: 960px) {
  .mt-7 {
    margin-top: 7rem;
    /* Full margin for larger screens */
  }

  .v-btn {
    font-size: 18px;
  }

  h2 {
    font-size: 28px;
  }
}
</style>
